<template>
  <div>
    <common-expansion-panel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        <span class="titulo-header">Método de pago</span>
      </template>

      <v-expansion-panel-content>
        <v-row v-if="!pagada">
          <v-col cols="6">
            <span class="text-body-1 px-1"
              >Introduce los datos de tu tarjeta</span
            >
          </v-col>
          <v-col cols="6" style="text-align: center;">
            <span class="text-body-1 px-1"
              >Tiempo para pagar tu poliza: {{ tiempoFormateado }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="!pagada" style="text-align: end;">
          <v-col cols="12">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  color="#00a7e4"
                  v-bind="attrs"
                  v-on="on"
                  @click="requiereAyuda"
                >
                  <v-icon dark>
                    mdi-help
                  </v-icon>
                </v-btn>
              </template>
              <span>Necesitas ayuda para pagar tu póliza ¡Da clic aquí!</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <div v-if="paymentData && tiempo > 0 && !pagada" style="width: 100%;">
          <v-row>
            <v-col cols="12">
              <v-botton>
                <v-btn
                    color="primary"
                    @click="copyToClipboard"
                  >
                    <v-icon left>mdi-content-copy</v-icon>
                    Copiar URL de pago
                  </v-btn>
              </v-botton>
            </v-col>
            <v-col cols="12">
              <iframe
                :src="paymentData.service_url"
                style="width: 100%; min-height: 1100px;"
                frameborder="0"
                @load="handleIframe"
              ></iframe>
            </v-col>
          </v-row>
        </div>
        <div v-if="pagada" style="width: 100%; min-height: 1100px;">
          <CommonSuccesPay :emisionId="paymentData.polizaId"></CommonSuccesPay>
        </div>
      </v-expansion-panel-content>
      <CommonActionModal
        :dialog="pagoFallidoModal"
        :title="titleModal"
        :subtitle="subtitleModal"
        :bottonText="bottonText"
        @userChoice="choiseSolicitud"
      />
    </common-expansion-panel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { validarPagoEmision } from "@/services/emision.service";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";
import CommonSuccesPay from "@/components/commonComponents/CommonSuccesPay.vue";

export default {
  components: {
    CommonExpansionPanel,
    CommonActionModal,
    CommonSuccesPay,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    clearIntervaloPago: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPanel: 1,
      tiempo: 300, // 5 minutos en segundos
      intervalo: null,
      pagoFallidoModal: false,
      titleModal: "",
      subtitleModal: "",
      bottonText: "",
      pagada: false,
    };
  },

  computed: {
    paymentData() {
      return this.$store.state.emission.paymentData;
    },
    tiempoFormateado() {
      const minutos = Math.floor(this.tiempo / 60);
      const segundos = this.tiempo % 60;
      return `${minutos}:${segundos < 10 ? "0" : ""}${segundos}`;
    },
  },

  watch: {
    active(v) {
      if (v) {
        this.currentPanel = 0;
        this.iniciarTimer();
      } else this.currentPanel = 1;
    },
    tiempo() {
      if (this.tiempo <= 0 && !this.pagada) {
        this.$emit("openHelpModal");
      }
    },
    clearIntervaloPago(v) {
      if (v) if (this.intervalo != null) clearInterval(this.intervalo);
    },
    "paymentData.service_url"() {
      this.tiempo = 300;
    },
  },

  methods: {
    async handleIframe() {
      var pagada = await validarPagoEmision({
        polizaId: this.paymentData.polizaId,
      });
      if (pagada.data.policy_paid) {
        this.pagada = true;
      }
    },
    iniciarTimer() {
      this.intervalo = setInterval(() => {
        if (this.tiempo > 0) {
          this.tiempo--;
          if (this.tiempo % 30 === 0) {
            this.handleIframe();
          }
        } else {
          clearInterval(this.intervalo);
        }
      }, 1000);
    },
    choiseSolicitud(val) {
      console.log(val);
    },
    requiereAyuda() {
      this.$emit("ayudaAlPagar");
    },
    async copyToClipboard() {
      // 1. Construir la URL dinámicamente
        const hostname = window.location.origin
        const baseUrl = '/interify/pago/';
        console.log(this.$store.state.emission)

        const params = {
          serviceUrl:this.paymentData.service_url,
          contractorData:this.$store.state.emission.contractorData,
          carData:this.$store.state.emission.carData,
          cotizacionSelected: JSON.parse(sessionStorage.getItem("cotizacionSelected"))
        }
        if(params.cotizacionSelected.benefits)
        delete params.cotizacionSelected.benefits
        if(params.cotizacionSelected.coverages)
        delete params.cotizacionSelected.coverages

        const fullUrl = hostname + baseUrl + '?jsonInfo=' + JSON.stringify(params); 

        try {
          // 2. Copiar la URL al portapapeles
          await navigator.clipboard.writeText(fullUrl);
          this.$toast('URL de pago copiada al portapapeles');
        } catch (error) {
          console.error("Error al copiar la URL:", error);
          this.$toast.error('No se pudo copiar la URL');
        }
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalo);
  },
};
</script>
